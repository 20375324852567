<template>
    <v-container grid-list-md pa-0 relative>
      <v-layout row wrap>
        <v-flex xs12 sm12>
          <v-card outlined class="primary--border">
            <v-card-title @click="cobraSecurity++" class="title">
              <span>{{cobraSecurity}}3. School's Payment List</span>
              <v-spacer></v-spacer>
              <add-button
                v-if="companyId"
                :on-current-batch="false"
                permission="school-create"
                @action="form.reset(), (form.dialog = true)"
                >Create New Payment
              </add-button>
            </v-card-title>
            <v-card outlined>
              <v-card-title>
                <v-flex xs2>
                  <v-select
                    v-model="payment_status"
                    :items="[
                      { value: 'all', text: 'All' },
                      { value: '1', text: 'Paid' },
                      { value: '2', text: 'Partial Paid' },
                      { value: '0', text: 'Pending' },
                      { value: '3', text: 'Pending Verification' },
                    ]"
                    class="pa-0"
                    label="Payment Status"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs3>
                  <v-select
                    v-model="selected_month"
                    :items="[{value:'',text:'All'},...months]"
                    class="pa-0"
                    label="Month"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs5>
                  <v-company-search-field
                    :displayClose="false"
                    v-model="companyId"
                  ></v-company-search-field>
                </v-flex>
                <v-flex>
                  <search-button style="" :permission="true" @action="get()">
                    Search
                  </search-button>
                </v-flex>
              </v-card-title>
            </v-card>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="form.items.data"
              :search="search"
              :options.sync="pagination"
              footer-props.items-per-page-options="rowsPerPageItems"
              :server-items-length="form.items.meta.total"
              :loading="form.loading"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-xs-left">{{ ++index }}</td>
                  <td class="text-left">
                    <v-chip
                      class="mr-2 mb-2"
                      outlined
                      label
                      v-for="(head, key) in item.payment_heads"
                      :key="key"
                      >{{ head.title }}
                      <strong> ({{ parseFloat(head.amount).numberFormat() }})</strong></v-chip
                    >
                  </td>
                  <td class="text-left">
                    <strong>{{ item.total_amount.numberFormat() }}</strong>
                  </td>
                  <td class="text-left">
                    {{ item.payment_date }} <br />
                    <small>{{ item.human }}</small>
                  </td>
                  <!-- receipt / voucher -->
                   <td class="text-xs-left">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!item.vouchers.length"
                      small
                      outlined
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left small>cloud_download</v-icon>&nbsp; Voucher
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(voucher, index) in item.vouchers"
                      :key="index"
                      @click="downloadDocument(voucher)"
                    >
                      <v-list-item-title
                        >Download Voucher ({{ index + 1 }})</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
                  <!-- <td class="text-center">{{ item.users }}</td>  -->
                  <td class="text-left">
                    <v-chip v-if="item.status === 1" label color="success">
                      <v-icon class="mr-2">check_circle</v-icon>
                      &nbsp;&nbsp;&nbsp;Complete
                    </v-chip>
                    <v-chip v-else-if="item.status === 2" label color="warning">
                      <v-icon class="mr-2">error</v-icon> &nbsp;&nbsp;&nbsp;Under
                      Review
                    </v-chip>
                    <v-chip v-else-if="item.status === 3" label color="warning">
                      <v-icon class="mr-2">pending_actions</v-icon> &nbsp;&nbsp;&nbsp;Verification Pending
                    </v-chip>
                    <v-chip label color="error" v-else>
                      <v-icon class="mr-2 material-icons">cancel</v-icon>
                      &nbsp;&nbsp;&nbsp;Pending
                    </v-chip>
                  </td>
                  <td class="text-right">
                    <edit-button
                      :onCurrentBatch="false"
                      v-if="(item.status === 0 && (cobraSecurity % 3) === 0)"
                      color="warning"
                      icon="edit"
                      @agree="form.edit(item), edit_status =1"
                      :permission="true"
                    >                    
                    </edit-button>
                    <edit-button
                      :editText="'Verify'"
                      :onCurrentBatch="false"
                      v-if="(item.status === 3 && (cobraSecurity % 3) === 0)"
                      color="warning"
                      icon="edit"
                      label="Verify Payment"
                      message="Are You sure you want to verify this payment?"
                      @agree="verifyPayment(item.id, item.status)"
                      :permission="true"
                    >
                    </edit-button>
                    <delete-button
                      :onCurrentBatch="false"
                      v-if="item.status === 0 && cobraSecurity === 3"
                      :permission="true"
                      @agree="form.delete(item.id)"
                    />
                  </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
            <v-card-actions>
              <v-spacer />
              <!--<v-pagination v-model="pagination.page" :length="form.items.meta.last_page"></v-pagination>-->
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-flex>
  
        <v-dialog v-model="form.dialog" scrollable persistent max-width="820px">
          <v-card outlined class="primary--border">
            <v-card-title class="primary white--text">
              <span class="title" v-if="edit_status == 0">Add</span>
              <span class="title" v-else>Edit</span>
              <span style="position: absolute; right: 5px">
                <v-btn-toggle dense style="float: right">
                  <v-btn
                    :disabled="parseInt(additionalRowCount) > 5"
                    @click="addNewRow"
                  >
                    Add
                  </v-btn>
                  <input
                    v-model="additionalRowCount"
                    type="text"
                    style="width: 60px"
                    :class="{
                      label_input_field: true,
                      error_x: parseInt(additionalRowCount) > 5,
                    }"
                  />
  
                  <v-btn
                    color="warning"
                    :disabled="parseInt(additionalRowCount) > 5"
                    @click="addNewRow"
                  >
                    More
                    {{ parseInt(additionalRowCount) > 1 ? "Rows" : "Row" }}
                  </v-btn>
                </v-btn-toggle>
              </span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12 sm6>
                       <v-select
                       v-model="form.plan_duration"
                        :items="planDuration"
                        class="pa-0"
                        label="Plan Duration"
                        outlined
                        dense
                        required
                        name="plan_period"
                        :error-messages="form.errors.get('plan_period')"
                        :rules="[(v) => !!v || 'Plan Duration is required']"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-calendar-field
                        v-model="form.payment_date"
                        id-val="payment_date"
                        label="Payment Date"
                        :rules="[v => !!v || 'Required']"
                      ></v-calendar-field>
                        <!-- :rules="[(v) => !!v || 'Payment Date is required']" -->
                    </v-flex>
                    <v-flex xs12 sm12>
                      <v-textarea
                        class="pa-0"
                        v-model="form.remarks"
                        outlined
                        name="remarks"
                        label="Remarks"
                        autocomplete="off"
                        dense
                        no-resize
                      ></v-textarea>
                    </v-flex>
                    <!-- Start Of Header Form -->
                    <div class="payment-head" id="paymentHead">
                      <h3 class="overline">Payment Head</h3>
  
                      <v-flex>
                        <v-card-title class="title">
                        </v-card-title>
                        <v-data-table
                          :server-items-length="form.payment_heads.length"
                          hide-default-footer
                          class="payment-table-head"
                          :headers="paymentHeaders"
                          :items="form.payment_heads"
                        >
                          <template v-slot:item="{ index, item }">
                            <tr :key="index">
                              <td style="max-width: 28px">
                                <v-select
                                  @change="updateButton = true"
                                  v-model="item.title"
                                  :items="types"
                                  height="30"
                                  dense
                                  outlined
                                ></v-select>
                              </td>
                              <td>
                                <v-text-field
                                  v-model="item.realamount"
                                  type="number"
                                  outlined
                                  dense
                                  @change="calculateAmountWithOutVat(index,item.realamount)"
                                ></v-text-field>
                              </td>
                              <td>
                                <v-text-field
                                  :error="
                                    item.amount.length > 0 &&
                                    parseInt(item.amount) < 1
                                  "
                                  v-model="item.amount"
                                  type="number"
                                  outlined
                                  dense
                                ></v-text-field>
                              </td>
                              <td>
                                <v-text-field
                                  :error="
                                    item.amount.length > 0 &&
                                    parseInt(item.amount) < 1
                                  "
                                  v-model="item.discount"
                                  type="number"
                                  outlined
                                  dense
                                ></v-text-field>
                              </td>
                              <td>
                                <delete-button style="margin-top: 0px !important;margin-bottom: 27px !important;"
                                  :permission="true"
                                  @agree="deleteRecord({ index, id: item.id,item })"
                                />
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </div>
                    <!-- End Of Header Form -->
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="(form.dialog = false), form.reset()"
                >Close</v-btn>
              <v-btn
                color="success"
                outlined
                text
                :loading="form.loading"
                @click="store"
                >
                {{edit_status == 0 ? save:update}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import Form from "@/library/Form";
  import { validateDate } from "../../../../library/helpers";
  import { genHeaders } from "../../../../library/helpers";
  import { getMonthNameFromString } from "../../../../library/helpers";
  import Mixins from "@/library/Mixins";
  const NepaliDate = require("nepali-date-js");
  
  export default {
    mixins: [Mixins],
  
    data: () => ({
      save:"Save",
      update:"Update",
      edit_status:0,
       valid: true,
      additionalRowCount: 1,
      cobraSecurity: 0,
      companyId: "",
      payment_status:'0',
      selected_month:'',
      form: new Form(
        {
          comp_id:'',
          payment_date: new NepaliDate(new Date()).format("YYYY-MM-DD"),
          // payment_date: "",
          remarks: "",
          plan_duration:"",
          payment_heads: [],
        },
        "/api/payment-alert"
      ),
      search: null,
      rowsPerPageItems: [5, 10, 25, 50, 75],
      pagination: {
        rowsPerPage: 50,
        descending: false,
      },
      paymentHeaders: genHeaders(["Type,160", "Amount,160","Amount(Vat Excluded),160", "Discount(%),150","Action,90"]),
      dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
  
      headers: [
        {
          text: "#",
          align: "left",
          value: "sn",
          width: 10,
          sortable: false,
        },
        {
          text: "Head",
          align: "left",
          value: "head",
          sortable: false,
          width: 150,
        },
        {
          text: "Amount",
          align: "left",
          value: "amount",
          sortable: false,
          width: 100,
        },
        {
          text: "Payment Date",
          align: "left",
          value: "payment_date",
          sortable: false,
          width: 100,
        },
        {
          text: "Receipt / Voucher",
          align: "left",
          value: "invoice_receipt",
          width: 100,
          sortable: false,
        },
        {
          text: "Status",
          align: "left",
          value: "status",
          width: 100,
          sortable: false,
        },
        { text: "Action", align: "right", sortable: false, width: 150 },
      ],
      chartData: [],
      chartOptions: {
        title: {
          text: "Role-wise Users",
          align: "center",
        },
        legend: {
          show: false,
          floating: true,
        },
        labels: [],
      },
      permissions: [],
      types: [
        { text: "Handling Fee", value: "handling" },
        { text: "SMS Purchase", value: "sms_purchase" },
        { text: "Video Conference", value: "video_conference"},
        { text: "VAT Amount", value: "vat_amount" },
        { text: "Domain & Hosting", value: "domain_hosting"},
        { text: "Domain Renewal", value: "domain_renewal"},
        { text: "Installation Charge", value: "installation_charge"},
      ],
      planDuration:[
         1,
         2,
         3,
         4,
         5,
         6,
         7,
         8,
         9,
         10,
        11,
        12,
      ],
    
     
    }),
    computed: {
      selectedRanks() {
        let output = [];
        this.form.items.data.filter(function (item) {
          if (item.rank !== 0) output.push(item.rank);
        });
        return output;
      },
      months() {
        const monthObj = getMonthNameFromString();
        return Object.keys(monthObj).map((value) => {
          return { text: monthObj[value].value, value: value };
        });
      },
    },
    watch: {
      pagination: function () {
        // this.get();
      },
    },
    methods: {
      calculateAmountWithOutVat(index,value){
        let vat_excluded_amount = value - (0.13 * value);
        this.form.payment_heads[index].amount = vat_excluded_amount;
      },
      downloadDocument(doc) {
        window.open(doc, "_blank");
      },
      validateData() {
        let validData = 0;
        try {
          this.form.payment_heads.map((item) => {
            if (item.amount.length < 1 || parseInt(item.amount) < 0) {
              throw Error("Here");
            } else {
              validData++;
            }
          });
        } catch (e) {
        }
        if (validData === this.form.payment_heads.length) {
          this.isAllValid = true;
        } else {
          this.isAllValid = false;
        }
      },
      deleteRecord({ id, index,item }) {
        if (id) {
          this.$rest.delete('/api/delete-payment-head/'+id)
          .then((res)=>{
            if(res.status == 200){
              this.$events.fire("notification", {
                message: "Deleted Successfully",
                status: "success",
              });
              this.get();
              // $( "#paymentHead" ).load(window.location.href + " #paymentHead" );
              // this.form.dialog = true;
            }
          }).catch((e)=>{
  
          })
        } else {
          this.form.payment_heads.splice(index, 1);
        }
      },
      addNewRow() {
        [...Array(parseInt(this.additionalRowCount))].map(() => {
          this.form.payment_heads.push({
            title: "handling",
            amount: "",
            realamount:""
          });
        });
        this.validateData();
      },
      store() {
        if (this.$refs.form.validate()) {
          this.form.comp_id = this.companyId;
          this.form.store();
        }
      },
      getPermissions(id) {
        this.permissions = [];
        this.$rest.get("/api/roles/" + id + "/permissions").then(({ data }) => {
          if (data.status === true) {
            this.permissions = data.permissions;
          }
        });
      },
  
      get() {
        let extraParams = "school_id="+"&payment_status="+this.payment_status+"&month="+this.selected_month+"&companyId="+this.companyId;
        // let extraParams = "&payment_status="+this.payment_status+"&month="+this.selected_month+"&companyId="+this.companyId;
        let query = this.queryString(extraParams);
        this.form.get(null, query).then(({ data }) => {
          let $this = this;
          let output = [];
          data.data.map(function (item) {
            if (item.slug === "super") {
              if ($this.$auth.getRole() === "super") {
                output.push(item);
              }
            } else {
              output.push(item);
            }
          });
          this.form.items.data = output;
          this.pagination.totalItems = data.meta.total;
  
          // this.setChart(data.data);
        });
      },
      
  
      setChart(data) {
        if (data == null) {
          data = this.form.items.data;
        }
        let labels = [];
        let chartData = [];
        data.map((item) => {
          if (item.name !== "Guardian") {
            labels.push(item.name);
            chartData.push(item.users);
          }
        });
  
        this.chartOptions.labels = labels;
        this.chartData = chartData;
      },

      verifyPayment(id,status){
        this.$rest
        .put("/api/verify-payment/" + id, {
          company_id: this.companyId,
          status: 1,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: "Approved successfully",
            status: "success",
          });
          this.get();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: "Failed",
            status: "error",
          });
        });
      }
      
    },
  };
  </script>
  
  <style lang="scss">
  .label_input_field {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
    text-align: center;
    &.error_x {
      color: red;
    }
    &:focus {
      outline: none;
    }
  }
  
  select.rank_update {
    outline: none;
  }
  .batch-migration {
    border: 1px solid #ccc;
    padding: 15px 15px 0 15px !important;
    position: relative;
    margin-top: 20px;
  }
  .payment-head {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px 15px 0 15px !important;
    position: relative;
    margin-top: 20px;
  }
  .payment-table-head td {
    padding-top: 10px;
    padding-bottom: 10px;
    .v-text-field.v-text-field--enclosed {
      margin-bottom: 0;
    }
  }
  .overline {
    font-size: 18px !important;
    position: absolute;
    top: -16px;
    background-color: white;
    padding: 0 7px;
  }
  .v-application .overline {
    font-size: 15px !important;
    text-transform: none;
    letter-spacing: 0px !important;
  }
  .v-textarea textarea {
        line-height: 10px;
   }
  </style>
  